<template>
  <div class="sale-field-box">
    <!-- <div class="title">
      <span class="tmall" @click="changeMenu(1)">
        <img src="https://cdn.cailu88.com/jingxuanshi/tmall_text.png" alt="">
        <label class="line" v-if="clickType == 1"></label>
      </span>
      <span class="jd" @click="changeMenu(2)">
        <img src="https://cdn.cailu88.com/jingxuanshi/jd_text.png" alt="">
        <label class="line" v-if="clickType == 2"></label>
      </span>
    </div> -->

    <div :class="showMenus ? 'menus slideup' : 'menus slideupdown'">
      <van-tabs @click="scrollMove" v-model="activeName" v-if="clickType == 1" style="width: 100%">
        <van-tab v-for="(item, index) in tamll_menus" :title="item.name" :name="item.cn">
        </van-tab>
      </van-tabs>
      <!-- <van-tabs @click="scrollMoveJd" v-model="jd_activeName" v-else style="width: 100%">
        <van-tab v-for="(item, index) in jd_menus" :title="item.name" :name="item.cn">
        </van-tab>
      </van-tabs> -->
    </div>
    <div class="tmall-box" ref="box_tmall" v-show="clickType == 1">
      <img src="https://cdn.cailu88.com/jingxuanshi/tmall_12_bg.png?v=1" alt="" class="tmall-bg">
      <div class="timeout">
        <!-- <p>本阶段距结束还有</p>
        <div class="timeruning">
          <div class="i-b">{{ day }}</div>
          天
          <div class="i-b">{{ hr }}</div>
          时
          <div class="i-b">{{ min }}</div>
          分
          <div class="i-b">{{ sec }}</div>
          秒 -->
        <!-- </div> -->
      </div>
      <img class="strategy" @click="goStrategyPage"
           src="https://cdn.cailu88.com/jingxuanshi/11details.png" alt="">
      <div class="step-one">
        <div class="top" ref="step-one" v-if="!timeout">
          <img class="button-l" src="https://cdn.cailu88.com/jingxuanshi/tmall_12_go.png" alt=""
               @click="click_step1_btn('get')">
          <img class="button-r" src="https://cdn.cailu88.com/jingxuanshi/tmall_12_share.png" alt=""
               @click="copyText($event)">
          <!-- <img class="button-r" v-if="!authStatus" src="https://cdn.cailu88.com/jingxuanshi/button_r.png" alt=""
               @click="click_step1_btn('share')">
          <img class="button-r" v-else src="https://cdn.cailu88.com/jingxuanshi/button_r.png" alt=""
               @click="copyText($event, 'advance')"> -->
        </div>
        <div class="top2" ref="step-one" v-else>
          <img class="button-l" @click="click_footer_btn('get')"
               src="https://cdn.cailu88.com/jingxuanshi/step2_button_l.png" alt="">
          <img class="button-r" v-if="!authStatus" @click="click_footer_btn('share')"
               src="https://cdn.cailu88.com/jingxuanshi/step2_button_r.png" alt="">
          <img class="button-r" @click="copyText($event)"
               src="https://cdn.cailu88.com/jingxuanshi/step2_button_r.png" alt="">
        </div>
        <div class="activity" ref="tmall-activity">
          <p class="title"><span class="line"></span>鲸选超值活动<span class="line"></span></p>
          <div class="activity-list">
            <template v-for="(item, index) in activityList">
              <img style="border-radius: 5px;" :src="item.pic" alt="" @click="activityInfo(index + 1)">
            </template>
          </div>
        </div>
        <div class="every-day-booking" ref="tmall-every">
          <p class="title"><span class="line"></span>每日预售好货<span class="line"></span></p>
          <p class="tips">精选预售好物，双十二最佳优惠提前看</p>
          <div class="goods-list">
            <template v-for="(item, index) in everyDayList">
              <div class="goods-wrap" :key="index" @click="goodsDetails(item)">
                <div class="goods-image">
                  <img :src="item.pictUrl"/>
                  </div>
                <div class="goods-info">
                  <div class="goods-title">
                    <span
                      :class="item.itemSource === 3 ? 'label-pdd' : item.itemSource === 4 ? 'label-wph' : item.itemSource === 5 ? 'label-sn' : item.itemSource === 2 ? 'label-jd' : item.itemSource === 1 && item.userType == 0 ? 'label-tb' : item.itemSource === 1 && item.userType == 1 ? 'label-tm' : ''">{{
                        item.itemSource === 3 ? '拼多多' : item.itemSource === 4 ? '唯品会' : item.itemSource === 5 ? '苏宁' : item.itemSource === 2 ? '京东' : item.itemSource === 1 && item.userType == 0 ? '淘宝' : item.itemSource === 1 && item.userType == 1 ? '天猫' : ''
                      }}</span>
                    <!-- <image class="jd-icon" azy-load="true" src="https://cdn.cailu88.com/jingxuanshi/jd_icon@2x.png"></image> -->
                    <span>{{ item.itemTitle }}</span>
                  </div>
                  <div class="goods-discounts">
                    <div class="goods-coupon" v-if="item.couponValue">
                      <span></span>{{ item.couponValue }}元券
                    </div>
                    <div v-if="item.rebateValue>0" class="goods-cashback">返 ¥{{ item.rebateValue }}</div>
                  </div>
                  <div class="goods-price-volume">
                    <div class="price">¥ {{ item.reservePrice }}</div>
                    <!--                  <div class="volume" v-if="item.volume">-->
                    <!--                    <span>{{item.volume}}人</span>-->
                    <!--                    <span> 已购</span>-->
                    <!--                  </div>-->
                  </div>
                  <div class="goods-promotion-volume">
                    <div>
                      <span class='money-symbol'>¥ </span>
                      <span class="goods-current-price">{{ item.zkFinalPrice }}</span>
                    </div>
                    <div class="to-details">
                      查看详情<img src="https://cdn.cailu88.com/jingxuanshi/tmall_arrow_r.png">
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
                 <p class="more" @click="jumpActivity">更多预售好货</p>
        </div>
        <div class="top-list" ref="tmall-top">
          <p class="title"><span class="line"></span>实时Top榜单<span class="line"></span></p>
          <p class="tips">为您推荐全网实时热门双十一必抢好物</p>
          <div class="swiper">
            <div class="swiper-box">
              <template v-for="(item, index) in topList">
                <div class="swiper-item" @click="goodsDetails(item)">
                  <img class="pic" :src="item.pictUrl" alt="">
                  <p class="title">{{ item.itemTitle }}</p>
                  <p class="price">￥ {{ item.reservePrice }}</p>
                  <label class="label">购买返 ¥ {{ item.rebateValue }}</label>
                </div>
              </template>
                         <!-- <img src="https://cdn.cailu88.com/jingxuanshi/top_more.png" class="top-more" alt=""> -->
            </div>
          </div>
            <!-- <p class="more">查看更多榜单好物</p> -->
        </div>
      </div>
      <div class="step-two">
        <!-- <div class="top" ref="tmall-step2" v-if="!timeout">
          <img class="button-l" @click="click_footer_btn('get')"
               src="https://cdn.cailu88.com/jingxuanshi/step2_button_l.png" alt="">
          <img class="button-r" @click="copyText($event)"
               src="https://cdn.cailu88.com/jingxuanshi/step2_button_r.png" alt="">
        </div> -->
        <div class="recommended" ref="tmall-recommended">
          <p class="title"><span class="line"></span>鲸选推荐<span class="line"></span></p>
          <div class="goods-list">
            <template v-for="(item, index) in goodsList">
              <div class="goods-wrap" :key="index" @click="goodsDetails(item)">
                <div class="goods-image">
                  <img :src="item.pictUrl"/>
                </div>
                <div class="goods-info">
                  <div class="goods-title">
                    <span
                      :class="item.itemSource === 3 ? 'label-pdd' : item.itemSource === 4 ? 'label-wph' : item.itemSource === 5 ? 'label-sn' : item.itemSource === 2 ? 'label-jd' : item.itemSource === 1 && item.userType == 0 ? 'label-tb' : item.itemSource === 1 && item.userType == 1 ? 'label-tm' : ''">{{
                        item.itemSource === 3 ? '拼多多' : item.itemSource === 4 ? '唯品会' : item.itemSource === 5 ? '苏宁' : item.itemSource === 2 ? '京东' : item.itemSource === 1 && item.userType == 0 ? '淘宝' : item.itemSource === 1 && item.userType == 1 ? '天猫' : ''
                      }}</span>
                    <!-- <image class="jd-icon" azy-load="true" src="https://cdn.cailu88.com/jingxuanshi/jd_icon@2x.png"></image> -->
                    <span>{{ item.itemTitle }}</span>
                  </div>
                  <div class="goods-discounts">
                    <div class="goods-coupon" v-if="item.couponValue">
                      <span></span>{{ item.couponValue }}元券
                    </div>
                    <div v-if="item.rebateValue>0" class="goods-cashback">返 ¥{{ item.rebateValue }}</div>
                  </div>
                  <div class="goods-price-volume">
                    <div class="price">¥ {{ item.reservePrice }}</div>
                    <!--                  <div class="volume" v-if="item.volume">-->
                    <!--                    <span>{{item.volume}}人</span>-->
                    <!--                    <span> 已购</span>-->
                    <!--                  </div>-->
                  </div>
                  <div class="goods-promotion-volume">
                    <div>
                      <span class='money-symbol'>¥ </span>
                      <span class="goods-current-price">{{ item.zkFinalPrice }}</span>
                    </div>
                    <div class="to-details">
                      查看详情<img src="https://cdn.cailu88.com/jingxuanshi/tmall_arrow_r.png">
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="loading-wrap">
              <img v-if="goodsList.length < totalCount" class="loading"
                   src="https://cdn.cailu88.com/jingxuanshi/loading@2x.png"/>
              <p v-if="goodsList.length == totalCount">
                暂无更多
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="jd-box" ref="box_jd" v-show="clickType == 2">
      <img src="https://cdn.cailu88.com/jingxuanshi/jd_bg.png?v=1" alt="" class="jd-bg">
      <button class="get-jd" @click="click_jd_footer_btn('get')">进入京东预售会场<img
        src="https://cdn.cailu88.com/jingxuanshi/jd_arrow_r.png" alt="" class="jd-arrow-r">
      </button>
      <div class="today-hot" ref="jd-hot">
        <p class="title"><span class="line"></span>今日热推爆款<span class="line"></span></p>
        <div class="goods-list">
          <template v-for="(item, index) in jdHotList">
            <div class="goods-wrap" :key="index" @click="goodsDetails(item)">
              <div class="goods-image">
                <img :src="item.pictUrl"/>
              </div>
              <div class="goods-info">
                <div class="goods-title">
                  <span
                    :class="item.itemSource === 3 ? 'label-pdd' : item.itemSource === 4 ? 'label-wph' : item.itemSource === 5 ? 'label-sn' : item.itemSource === 2 ? 'label-jd' : item.itemSource === 1 && item.userType == 0 ? 'label-tb' : item.itemSource === 1 && item.userType == 1 ? 'label-tm' : ''">{{
                      item.itemSource === 3 ? '拼多多' : item.itemSource === 4 ? '唯品会' : item.itemSource === 5 ? '苏宁' : item.itemSource === 2 ? '京东' : item.itemSource === 1 && item.userType == 0 ? '淘宝' : item.itemSource === 1 && item.userType == 1 ? '天猫' : ''
                    }}</span>
                  <!-- <image class="jd-icon" azy-load="true" src="https://cdn.cailu88.com/jingxuanshi/jd_icon@2x.png"></image> -->
                  <span>{{ item.itemTitle }}</span>
                </div>
                <div class="goods-discounts">
                  <div class="goods-coupon" v-if="item.couponValue">
                    <span></span>{{ item.couponValue }}元券
                  </div>
                  <div v-if="item.rebateValue>0" class="goods-cashback">返 ¥{{ item.rebateValue }}</div>
                </div>
                <div class="goods-price-volume">
                  <div class="price">¥ {{ item.reservePrice }}</div>
                  <!--                  <div class="volume" v-if="item.volume">-->
                  <!--                    <span>{{item.volume}}人</span>-->
                  <!--                    <span> 已购</span>-->
                  <!--                  </div>-->
                </div>
                <div class="goods-promotion-volume">
                  <div>
                    <span class='money-symbol'>¥ </span>
                    <span class="goods-current-price">{{ item.zkFinalPrice }}</span>
                  </div>
                  <div class="to-details">
                    查看详情<img src="https://cdn.cailu88.com/jingxuanshi/tmall_arrow_r.png">
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <!--        <p class="more">更多爆款</p>-->
      </div>
      <div class="top-list" ref="jd-top">
        <p class="title"><span class="line"></span>实时Top榜单<span class="line"></span></p>
        <p class="tips">为您推荐全网实时热门双十一必抢好物</p>
        <div class="main-box">
          <div class="swiper">
            <template v-for="(item, index) in jdTopList">
              <div class="swiper-item" @click="goodsDetails(item)">
                <img class="pic" :src="item.pictUrl" alt="">
                <p class="title">{{ item.itemTitle }}</p>
                <p class="old-price">￥ {{ item.reservePrice }}</p>
                <label class="price">¥ {{ item.zkFinalPrice }}</label>
              </div>
            </template>
          </div>
          <!--          <p class="more">查看完整榜单</p>-->
        </div>
      </div>
      <!--      <div class="activity" ref="jd-activity">-->
      <!--        <p class="title"><span class="line"></span>品牌专区<span class="line"></span></p>-->
      <!--        <div class="activity-list">-->
      <!--          <template  v-for="(item, index) in jdActivityList">-->
      <!--            <img :src="item.pic" alt="">-->
      <!--          </template>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="recommended" ref="jd-recommended">
        <p class="title"><span class="line"></span>鲸选推荐<span class="line"></span></p>
        <div class="goods-list">
          <template v-for="(item, index) in jdRecommendedList">
            <div class="goods-wrap" :key="index" @click="goodsDetails(item)">
              <div class="goods-image">
                <img :src="item.pictUrl"/>
              </div>
              <div class="goods-info">
                <div class="goods-title">
                  <span
                    :class="item.itemSource === 3 ? 'label-pdd' : item.itemSource === 4 ? 'label-wph' : item.itemSource === 5 ? 'label-sn' : item.itemSource === 2 ? 'label-jd' : item.itemSource === 1 && item.userType == 0 ? 'label-tb' : item.itemSource === 1 && item.userType == 1 ? 'label-tm' : ''">{{
                      item.itemSource === 3 ? '拼多多' : item.itemSource === 4 ? '唯品会' : item.itemSource === 5 ? '苏宁' : item.itemSource === 2 ? '京东' : item.itemSource === 1 && item.userType == 0 ? '淘宝' : item.itemSource === 1 && item.userType == 1 ? '天猫' : ''
                    }}</span>
                  <!-- <image class="jd-icon" azy-load="true" src="https://cdn.cailu88.com/jingxuanshi/jd_icon@2x.png"></image> -->
                  <span>{{ item.itemTitle }}</span>
                </div>
                <div class="goods-discounts">
                  <div class="goods-coupon" v-if="item.couponValue">
                    <span></span>{{ item.couponValue }}元券
                  </div>
                  <div v-if="item.rebateValue>0" class="goods-cashback">返 ¥{{ item.rebateValue }}</div>
                </div>
                <div class="goods-price-volume">
                  <div class="price">¥ {{ item.reservePrice }}</div>
                  <!--                  <div class="volume" v-if="item.volume">-->
                  <!--                    <span>{{item.volume}}人</span>-->
                  <!--                    <span> 已购</span>-->
                  <!--                  </div>-->
                </div>
                <div class="goods-promotion-volume">
                  <div>
                    <span class='money-symbol'>¥ </span>
                    <span class="goods-current-price">{{ item.zkFinalPrice }}</span>
                  </div>
                  <div class="to-details">
                    查看详情<img src="https://cdn.cailu88.com/jingxuanshi/tmall_arrow_r.png">
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="loading-wrap">
            <img v-if="jdRecommendedList.length < jd_totalCount" class="loading"
                 src="https://cdn.cailu88.com/jingxuanshi/loading@2x.png"/>
            <p v-if="jdRecommendedList.length === jd_totalCount">
              暂无更多
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <template v-if="clickType == 1">
        <button class="get" @click="click_footer_btn('get')">
          <span>领取超级红包</span>
          <span>(每天3次 最高1212元)</span>
        </button>
        <button class="share" v-if="!authStatus" @click="copyText($event)">
          <span>分享超级红包口令</span>
          <span>(超级锁佣)</span>
        </button>
        <button class="share" v-else @click="copyText($event)">
          <span>分享超级红包口令</span>
          <span>(超级锁佣)</span>
        </button>
      </template>
      <template v-if="clickType == 2">
        <button class="get" @click="click_jd_footer_btn('get')">
          <span>进入京东预售会场</span>
          <span>（199-100超值好券）</span>
        </button>
        <button class="share" @click="copyText($event)">
          <span>分享京东预售会场</span>
          <span>(超级锁佣)</span>
        </button>
      </template>
    </div>
  </div>
  <!--    </div>-->
</template>

<script>
  import Vue from 'vue'
  import { Tab, Tabs, Toast } from 'vant'
  import clipboard from '@/utils/copy'
  import baseUrl from '@/utils/baseUrl'
  import moment from 'moment/moment'

  const api = require('../../utils/api').api

  Vue.use(Tab)
Vue.use(Tabs)
export default {
  name: 'index',
  data () {
    return {
      test1: 0,
      test2: 0,
      authStatus: false,
      req: true,
      totalCount: 0,
      jd_totalCount: 0,
      id: '20150318020002508',
      idAc: '20150318020002540',
      idAc2: '20150318020002571',
      version: '',
      token: '',
      channel: '',
      appClient: '',
      message: '',
      advanceMessage: '',
      timeout: false,
      activeName: 'tmall-step1',
      page_tmall: 1,
      page_jd: 1,
      jd_activeName: 'jd-hot',
      jdRecommendedList: [],
      jdActivityList: [],
      jdTopList: [],
      jdHotList: [],
      tmall_scroll_list: {
        step1: {
          top: '',
          bottom: ''
        },
        every: {
          top: '',
          bottom: ''
        },
        top: {
          top: '',
          bottom: ''
        },
        activity: {
          top: '',
          bottom: ''
        },
        step2: {
          top: '',
          bottom: ''
        },
        recommended: {
          top: '',
          bottom: ''
        }
      },
      jd_scroll_list: {
        hot: {
          top: '',
          bottom: ''
        },
        top: {
          top: '',
          bottom: ''
        },
        activity: {
          top: '',
          bottom: ''
        },
        recommended: {
          top: '',
          bottom: ''
        }
      },
      tamll_menus: [
        {
          name: '天猫双十二',
          cn: 'tmall-step1'
        },
        {
          name: '鲸选活动',
          cn: 'tmall-activity'
        },
        {
          name: '每日预售',
          cn: 'tmall-every'
        },
        {
          name: 'Top榜单',
          cn: 'tmall-top'
        },
        // {
        //   name: '第二阶段',
        //   cn: 'tmall-step2'
        // },
        {
          name: '鲸选推荐',
          cn: 'tmall-recommended'
        }
      ],
      jd_menus: [
        {
          name: '今日爆款',
          cn: 'jd-hot'
        },
        {
          name: 'Top榜单',
          cn: 'jd-top'
        },
        // {
        //   name: '品牌专区',
        //   cn: 'jd-activity'
        // },
        {
          name: '鲸选推荐',
          cn: 'jd-recommended'
        }
      ],
      showMenus: false,
      day: 0,
      hr: 0,
      min: 0,
      sec: 0,
      clickType: 1,
      activityList: [
        {
          pic: 'https://cdn.cailu88.com/jingxuanshi/tmall_12_activity_1.png'
        },
        {
          pic: 'https://cdn.cailu88.com/jingxuanshi/tmall_12_activity_2.png'
        },
        {
          pic: 'https://cdn.cailu88.com/jingxuanshi/tmall_12_activity_3.png'
        }
      ],
      everyDayList: [],
      topList: [],
      goodsList: [],
    }
  },
  created () {
  },
  mounted () {
    // this.countdown()
    this.token = this.$route.query.token
    this.version = this.$route.query.version
    this.channel = this.$route.query.channel
    this.appClient = this.$route.query.appClient
    this.page_tmall = 1
    this.page_jd = 1
    window.addEventListener('scroll', this.scroll)
    this.$once('hook:beforeDestroy', () => {
      this.countdown = null
      window.removeEventListener('scroll', this.scroll)
    })
    this.$axios('item/activity/query', {
      params: {
        activityId: 281,
        page: 1,
        pageSize: 3
      }
    }).then(res => {
      this.everyDayList = res.data.data.data
      this.$axios('item/activity/query', {
        params: {
          activityId: 282,
          page: 1,
          pageSize: 3
        }
      }).then(res => {
        this.topList = res.data.data.data
        this.$axios('item/activity/query', {
          params: {
            activityId: 283,
            page: 1,
            pageSize: 20,
            itemSource: 1
          }
        }).then(res => {
          window.scrollTo({
            top: 0
          })
          this.goodsList = res.data.data.data
          this.totalCount = res.data.data.totalCount
          this.tmall_scroll_list.step1.top = this.$refs['step-one'].getBoundingClientRect().top
          this.tmall_scroll_list.step1.bottom = this.$refs['step-one'].getBoundingClientRect().bottom

          this.tmall_scroll_list.every.top = this.$refs['tmall-every'].getBoundingClientRect().top
          this.tmall_scroll_list.every.bottom = this.$refs['tmall-every'].getBoundingClientRect().bottom

          this.tmall_scroll_list.top.top = this.$refs['tmall-top'].getBoundingClientRect().top
          this.tmall_scroll_list.top.bottom = this.$refs['tmall-top'].getBoundingClientRect().bottom

          this.tmall_scroll_list.activity.top = this.$refs['tmall-activity'].getBoundingClientRect().top
          this.tmall_scroll_list.activity.bottom = this.$refs['tmall-activity'].getBoundingClientRect().bottom

          // if (!this.timeout) {
          //   this.tmall_scroll_list.step2.top = this.$refs['tmall-step2'].getBoundingClientRect().top
          //   this.tmall_scroll_list.step2.bottom = this.$refs['tmall-step2'].getBoundingClientRect().bottom
          // }

          this.tmall_scroll_list.recommended.top = this.$refs['tmall-recommended'].getBoundingClientRect().top
          this.tmall_scroll_list.recommended.bottom = this.$refs['tmall-recommended'].getBoundingClientRect().bottom

        })
      })
    })
    this.$axios('item/activity/query', {
      params: {
        activityId: 278,
        page: 1,
        pageSize: 3
      }
    }).then(res => {
      this.jdHotList = res.data.data.data
    })
    this.$axios('item/activity/query', {
      params: {
        activityId: 279,
        page: 1,
        pageSize: 3
      }
    }).then(res => {
      this.jdTopList = res.data.data.data
    })
    this.$axios('item/activity/query', {
      params: {
        activityId: 280,
        page: 1,
        pageSize: 20,
        itemSource: 2
      }
    }).then(res => {
      this.jd_totalCount = res.data.data.totalCount
      this.jdRecommendedList = res.data.data.data
    })
    this.init()

  },
  methods: {

    init () {
      let query = {}
      if (this.clickType == 1) {
        query = {
          tbActivityMaterialId: this.id
        }
        this.$axios(api.auth, {}).then(res => {
          if (res.data.data.authStatus == 0) {
            this.authStatus = false
          } else {
            this.authStatus = true
            this.$axios.post(api.strategyUrl, {
              tbActivityMaterialId: 20150318020002508
            }).then(res => {
              this.advanceMessage = res.data.data.shareContent
            }).catch(err => {
            })
          }
        })
      } else {
        query = {
          jdActivityMaterialId: 1
        }
      }
          this.$axios.post(api.strategyUrl, query).then(res => {
            this.message = res.data.data.shareContent
          }).catch(err => {
          })
    },

    //复制
    copyText (event) {
      let that = this
      this.$.ajax({
        url: baseUrl.baseUrl + api.auth,
        type: 'get',
        headers: {
          'token': that.token,
          'version': that.version,
          'channel': that.channel,
          'appClient': that.appClient,
          'Content-Type': 'application/json;charset=UTF-8'
        },
        async: false,
        success: function (res) {
          if (res.code !== 'USER_UN_LOGIN' && res.code) {
            if (res.data.authStatus == 0) {
              that.$h5AppLink('openTbAuth', {
                authUrl: res.data.authUrl,
                backUrl: `${baseUrl.pageUrl}/subsidies`
              })
            } else {
              if (that.message) {
                Toast.success({
                    duration: 500, // 持续展示 toast
                    message: '复制成功'
                  })
                return;
              }
              that.$.ajax({
                url: baseUrl.baseUrl + api.strategyUrl,
                type: 'post',
                headers: {
                  'token': that.token,
                  'version': that.version,
                  'channel': that.channel,
                  'appClient': that.appClient,
                  'Content-Type': 'application/json;charset=UTF-8'
                },
                data: JSON.stringify({
                  shareScene: true,
                  tbActivityMaterialId: that.id,
                  clientType: 2
                }),
                async: false,
                success: function (res_t) {
                  Toast.success({
                    duration: 500, // 持续展示 toast
                    message: '复制成功'
                  })
                  that.message = res_t.data.shareContent
                }
              })
            }
          } else {
            that.isLogin()
            return false
          }

        }
      })
      clipboard(this.message, event)
    },

    goStrategyPage () {
      this.$router.push({
        name: 'strategy',
        query: {
          token: this.token,
          version: this.version,
          appClient: this.appClient,
          channel: this.channel
        }
      })
    },
    getJdList (type) {
      if (!this.req) {
        return false
      }
      this.req = false
      this.$axios('item/activity/query', {
        params: {
          activityId: 280,
          page: this.page_jd,
          pageSize: 20,
          itemSource: 2
        }
      }).then(res => {
        this.req = true
        this.jdRecommendedList = type ? [...this.jdRecommendedList, ...res.data.data.data] : res.data.data.data
        this.jd_totalCount = res.data.data.totalCount
      }).catch(err => {
        this.req = true
      })
    },
    getTmallList (type) {
      if (!this.req) {
        return false
      }
      this.page_tmall++
      this.req = false
      this.$axios('item/activity/query', {
        params: {
          activityId: 283,
          page: this.page_tmall,
          pageSize: 20,
          itemSource: 1
        }
      }).then(res => {
        this.req = true
        this.goodsList = type ? [...this.goodsList, ...res.data.data.data] : res.data.data.data
        this.totalCount = res.data.data.totalCount
      }).catch(err => {
        this.req = true
      })
    },
    click_step1_btn (type) {
      this.$axios(api.auth, {}).then(res => {
        if (res.data.data.authStatus == 0) {
          this.$h5AppLink('openTbAuth', {
            authUrl: res.data.data.authUrl,
            backUrl: `${baseUrl.pageUrl}/saleField`
          })
        } else {
          this.$axios.post('/item/expand/transfer', {
            tbActivityMaterialId: this.id
          }).then(res => {
            if (type === 'share') {
              this.message = res.data.data.shareContent
              this.authStatus = true
              // Toast.success({
              //   duration: 500, // 持续展示 toast
              //   message: '复制成功'
              // })
              // setTimeout(() => {
              //   // this.$refs['copyBtn'].click();
              //   this.$copyText(this.message).then(() => {
              //     this.onSuccess()
              //   }).catch(() => {
              //     this.onError()
              //   })
              // })
            } else {
              let paramObj = {
                version: this.version,
                url: res.data.data.spreadUrl,
                backUrl: `${baseUrl.pageUrl}/saleField`
              }
              this.$h5AppLink('version', paramObj)

            }
          }).catch(err => {
          })
        }
      }).catch(err => {
        if (err.data.code == 'USER_UN_LOGIN') {
          this.$h5AppLink('login', {
            backUrl: `${baseUrl.pageUrl}/saleField`
          })
        }
      })
    },
    click_jd_footer_btn (btnType) {
      this.$axios.post('/item/expand/transfer', {
        jdActivityMaterialId: 1
      }).then(res => {
        if (btnType === 'share') {
          this.message = res.data.data.shareContent
          Toast.success({
            duration: 500, // 持续展示 toast
            message: '复制成功'
          })
          setTimeout(() => {
            // this.$refs['copyBtn'].click();
            this.$copyText(this.message).then(() => {
              this.onSuccess()
            }).catch(() => {
              this.onError()
            })
          })
        } else {
          let eventName = ''
          let paramObj = {}
          eventName = 'jumpJD'
          paramObj = {
            url: res.data.data.spreadUrl,
          }
          this.$h5AppLink(eventName, paramObj)
        }
      }).catch(err => {
        if (err.data.code == 'USER_UN_LOGIN') {
          this.$h5AppLink('login', {
            backUrl: `${baseUrl.pageUrl}/saleField`
          })
        }
      })
    },
    click_footer_btn (btnType) {
      this.$axios(api.auth, {}).then(res => {
        if (res.data.data.authStatus == 0) {
          this.$h5AppLink('openTbAuth', {
            authUrl: res.data.data.authUrl,
            backUrl: `${baseUrl.pageUrl}/saleField`
          })
        } else {
          this.$axios.post('/item/expand/transfer', {
            tbActivityMaterialId: this.id
          }).then(res => {
            if (btnType === 'share') {
              this.message = res.data.data.shareContent
              this.authStatus = true
              // Toast.success({
              //   duration: 500, // 持续展示 toast
              //   message: '复制成功'
              // })
              // setTimeout(() => {
              //   // this.$refs['copyBtn'].click();
              //   this.$copyText(this.message).then(() => {
              //     this.onSuccess()
              //   }).catch(() => {
              //     this.onError()
              //   })
              // })
            } else {
              let eventName = ''
              let paramObj = {
                version: this.version,
                url: res.data.data.spreadUrl,
                backUrl: `${baseUrl.pageUrl}/saleField`
              }
              this.$h5AppLink('version', paramObj)
            }
          })
        }
      }).catch(err => {
        if (err.data.code == 'USER_UN_LOGIN') {
          this.$h5AppLink('login', {
            backUrl: `${baseUrl.pageUrl}/saleField`
          })
        }
      })

    },
    scrollMove (name, title) {
      switch (name) {
        case 'tmall-step1':
          window.scrollTo({
            top: this.tmall_scroll_list.step1.top - 50
          })
          break
        case 'tmall-every':
          window.scrollTo({
            top: this.tmall_scroll_list.every.top - 50
          })
          break
        case 'tmall-top':
          window.scrollTo({
            top: this.tmall_scroll_list.top.top - 50
          })
          break
        case 'tmall-activity':
          window.scrollTo({
            top: this.tmall_scroll_list.activity.top - 50
          })
          break
        // case 'tmall-step2':
        //   window.scrollTo({
        //     top: this.tmall_scroll_list.step2.top - 50
        //   })
        //   break
        case 'tmall-recommended':
          window.scrollTo({
            top: this.tmall_scroll_list.recommended.top - 50
          })
          break
      }
    },
    scrollMoveJd (name, title) {
      // console.log(this.$refs[name].getBoundingClientRect().top,this.$refs[name].getBoundingClientRect().bottom );
      switch (name) {
        case 'jd-hot':
          window.scrollTo({
            top: this.jd_scroll_list.hot.top - 40
          })
          break
        case 'jd-top':
          window.scrollTo({
            top: this.jd_scroll_list.top.top - 40
          })
          break
        case 'jd-activity':
          window.scrollTo({
            top: this.jd_scroll_list.activity.top - 40
          })
          break
        case 'jd-recommended':
          window.scrollTo({
            top: this.jd_scroll_list.recommended.top - 40
          })
          break
      }
    },
    countdown: function () {
      // 定义结束时间戳
      const endTime = '2020-11-03 23:59:59'
      const changeTime = moment(endTime).format('YYYY/MM/DD HH:mm:ss')
      const end = this.transformTime(changeTime)
      // 定义当前时间戳
      const now = Date.parse(new Date())
      // 做判断当倒计时结束时都为0
      if (now >= end) {
        this.day = 0
        this.hr = 0
        this.min = 0
        this.sec = 0
        this.timeout = true
        this.tamll_menus = [
          {
            name: '第一阶段',
            cn: 'tmall-step1'
          },
          {
            name: '每日预售',
            cn: 'tmall-every'
          },
          {
            name: 'Top榜单',
            cn: 'tmall-top'
          },
          {
            name: '鲸选活动',
            cn: 'tmall-activity'
          },
          {
            name: '鲸选推荐',
            cn: 'tmall-recommended'
          }
        ]
        // this.countdown2()
        return
      }
      // 用结束时间减去当前时间获得倒计时时间戳
      // const msec = end - now
      // let day = parseInt(msec / 1000 / 60 / 60 / 24) //算出天数
      // let hr = parseInt(msec / 1000 / 60 / 60 % 24)//算出小时数
      // let min = parseInt(msec / 1000 / 60 % 60)//算出分钟数
      // let sec = parseInt(msec / 1000 % 60)//算出秒数
      //给数据赋值
      // this.day = day
      // this.hr = hr > 9 ? hr : '0' + hr
      // this.min = min > 9 ? min : '0' + min
      // this.sec = sec > 9 ? sec : '0' + sec
      //定义this指向
      const that = this
      // 使用定时器 然后使用递归 让每一次函数能调用自己达到倒计时效果
      setTimeout(function () {
        that.countdown()
      }, 1000)
    },
    countdown2: function () {
      // 定义结束时间戳
      const endTime = '2020-11-13 23:59:59'
      const changeTime = moment(endTime).format('YYYY/MM/DD HH:mm:ss')
      const end = this.transformTime(changeTime)
      // 定义当前时间戳
      const now = Date.parse(new Date())
      // 做判断当倒计时结束时都为0
      if (now >= end) {
        this.day = 0
        this.hr = 0
        this.min = 0
        this.sec = 0
        return
      }
      // 用结束时间减去当前时间获得倒计时时间戳
      const msec = end - now
      let day = parseInt(msec / 1000 / 60 / 60 / 24) //算出天数
      let hr = parseInt(msec / 1000 / 60 / 60 % 24)//算出小时数
      let min = parseInt(msec / 1000 / 60 % 60)//算出分钟数
      let sec = parseInt(msec / 1000 % 60)//算出秒数
      //给数据赋值
      this.day = day
      this.hr = hr > 9 ? hr : '0' + hr
      this.min = min > 9 ? min : '0' + min
      this.sec = sec > 9 ? sec : '0' + sec
      //定义this指向
      const that = this
      // 使用定时器 然后使用递归 让每一次函数能调用自己达到倒计时效果
      setTimeout(function () {
        that.countdown2()
      }, 1000)
    },
    goodsDetails (item) {
      this.$h5AppLink('openGoodsInfo', {
        'itemId': item.itemId,
        'itemSource': item.itemSource,
        'sellerId': item.sellerId
      })
    },
    transformTime (t) {
      //利用moment工具生成date对象
      let date = moment(t).toDate()
      //变成秒级时间戳
      return moment(date).valueOf()
    },
  //  跳转活动
    jumpActivity(){
      let paramObj = {
        id: '281'
      }
      this.$h5AppLink('openActivity', paramObj)
    },
    activityInfo (ind) {
      this.$axios(api.auth, {}).then(res => {
        if (res.data.data.authStatus == 0) {
          this.$h5AppLink('openTbAuth', {
            authUrl: res.data.data.authUrl,
            backUrl: `${baseUrl.pageUrl}/saleField`
          })
        } else {
          this.$axios.post('/item/expand/transfer', {
            tbActivityMaterialId: ind == 1 ? this.idAc : ind == 2 ? this.idAc2 : '20150318020002367'
          }).then(res => {
            let paramObj = {
              version: this.version,
              url: res.data.data.spreadUrl,
              backUrl: `${baseUrl.pageUrl}/saleField`
            }
            this.$h5AppLink('version', paramObj)
          })
        }
      }).catch(err => {
        if (err.data.code == 'USER_UN_LOGIN') {
          this.$h5AppLink('login', {
            backUrl: `${baseUrl.pageUrl}/saleField`
          })
        }
      })

    },
    changeMenu (type) {
      this.clickType = type
      this.init()
      if (type == 2) {
        this.page_jd = 1
        setTimeout(() => {
          this.jd_scroll_list.hot.top = this.$refs['jd-hot'].getBoundingClientRect().top
          this.jd_scroll_list.hot.bottom = this.$refs['jd-hot'].getBoundingClientRect().bottom

          this.jd_scroll_list.top.top = this.$refs['jd-top'].getBoundingClientRect().top
          this.jd_scroll_list.top.bottom = this.$refs['jd-top'].getBoundingClientRect().bottom

          this.jd_scroll_list.activity.top = this.$refs['jd-activity'].getBoundingClientRect().top
          this.jd_scroll_list.activity.bottom = this.$refs['jd-activity'].getBoundingClientRect().bottom

          this.jd_scroll_list.recommended.top = this.$refs['jd-recommended'].getBoundingClientRect().top
          this.jd_scroll_list.recommended.bottom = this.$refs['jd-recommended'].getBoundingClientRect().bottom
        }, 1000)
      } else {
        this.page_tmall = 1
      }
      // this.init()
    },
    scroll () {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let clientHeight = document.documentElement.clientHeight
      let scrollHeight = document.documentElement.scrollHeight
      if (this.$refs['box_tmall'].getBoundingClientRect().top < 0 || this.$refs['box_jd'].getBoundingClientRect().top < 0) {
        this.showMenus = true
      } else {
        this.showMenus = false
      }
      if (this.clickType == 1) {
        if (scrollTop + clientHeight + 10 >= scrollHeight) { // 滚动到底部，逻辑代码//事件处理
          if (this.goodsList.length < this.totalCount && this.totalCount !== 0) {
            this.getTmallList('up')
          }
        }
        if (!this.timeout) {
          if (scrollTop > this.tmall_scroll_list.step1.top - 40 && scrollTop < this.tmall_scroll_list.step1.bottom - 40) {
            this.activeName = 'tmall-step1'
          } else if (scrollTop > this.tmall_scroll_list.activity.bottom - 40 && scrollTop < this.tmall_scroll_list.every.bottom - 40) {
            this.activeName = 'tmall-every'
          } else if (scrollTop > this.tmall_scroll_list.top.top - 40 && scrollTop < this.tmall_scroll_list.top.bottom - 40) {
            this.activeName = 'tmall-top'
          } else if (scrollTop > this.tmall_scroll_list.step1.bottom - 40 && scrollTop < this.tmall_scroll_list.activity.bottom - 40) {
            this.activeName = 'tmall-activity'
          // } else if (scrollTop > this.tmall_scroll_list.step2.top - 40 && scrollTop < this.tmall_scroll_list.step2.bottom - 40) {
            // this.activeName = 'tmall-step2'
          } else if (scrollTop > this.tmall_scroll_list.top.bottom - 40) {
            this.activeName = 'tmall-recommended'
          }
        // } else {
        //   if (scrollTop > this.tmall_scroll_list.step1.top - 40 && scrollTop < this.tmall_scroll_list.step1.bottom - 40) {
        //     this.activeName = 'tmall-step1'
        //   } else if (scrollTop > this.tmall_scroll_list.step1.bottom - 40 && scrollTop < this.tmall_scroll_list.every.bottom - 40) {
        //     this.activeName = 'tmall-every'
        //   } else if (scrollTop > this.tmall_scroll_list.top.top - 40 && scrollTop < this.tmall_scroll_list.top.bottom - 40) {
        //     this.activeName = 'tmall-top'
        //   } else if (scrollTop > this.tmall_scroll_list.top.bottom - 40 && scrollTop < this.tmall_scroll_list.activity.bottom - 40) {
        //     this.activeName = 'tmall-activity'
        //   } else if (scrollTop > this.tmall_scroll_list.activity.bottom - 40) {
        //     this.activeName = 'tmall-recommended'
        //   }
        }

      } else {
        if (scrollTop + clientHeight >= scrollHeight) { // 滚动到底部，逻辑代码//事件处理
          if (this.jdRecommendedList.length < this.jd_totalCount && this.jd_totalCount !== 0) {
            this.page_jd++
            this.getJdList('up')
          }
        }
        if (scrollTop > this.jd_scroll_list.hot.top - 40 && scrollTop < this.jd_scroll_list.hot.bottom - 40) {
          this.jd_activeName = 'jd-hot'
        } else if (scrollTop > this.jd_scroll_list.hot.bottom - 40 && scrollTop < this.jd_scroll_list.top.bottom - 40) {
          this.jd_activeName = 'jd-top'
        }
          // else if (scrollTop > this.jd_scroll_list.top.bottom - 40 && scrollTop < this.jd_scroll_list.activity.bottom - 40) {
          //   this.jd_activeName = 'jd-activity'
        // }
        else if (scrollTop > this.jd_scroll_list.top.bottom) {
          this.jd_activeName = 'jd-recommended'
        }
      }

    },

  }
}
</script>

<style scoped lang="less">
/deep/.menus{
  .van-tabs{
    .van-tab:first-child {
        span{
          width: 75px;
        }
      }
  }
}
.loading-wrap {
  font-size: 12px;
  line-height: 17px;
  color: white;
  text-align: center;
  padding-top: 15px;

  .loading {
    width: 35px;
    height: 35px;
    animation: loading 1s linear 0s infinite;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.van-tabs__line {
  bottom: 5px;
  width: 24px;
  height: 3px;
  background: #FE7D00;
  border-radius: 2px;
}

.slidedown {
  top: -100% !important;
  transition: all 0.5s;
  transform: translateY(-100%);
}

.slideup {
  top: 0 !important;
  transition: all 0.5s;
  transform: translateY(0%);
}

.sale-field-box {
  position: relative;
  background: #FE7D00;

  .menus {
    width: 375px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: -100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      display: flex;
      flex-direction: column;
      width: 200px;
    }

  }

  .footer {
    width: 375px;
    height: 60px;
    background: #FFFFFF;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 0 12px;
    box-sizing: border-box;
    justify-content: space-between;

    .get {
      width: 167px;
      height: 45px;
      background: linear-gradient(90deg, #FF004F 0%, #FF5500 100%);
      box-shadow: 0px 2px 4px 0px rgba(255, 31, 50, 0.6);
      border-radius: 25px;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;

      span:first-child {
        font-size: 18px;
      }

      span:last-child {
        font-size: 10px;
        margin-top: 2px;
      }
    }

    .share {
      width: 167px;
      height: 45px;
      background: linear-gradient(90deg, #A107FF 0%, #CA50FF 100%);
      box-shadow: 0px 2px 4px 0px rgba(167, 18, 255, 0.3);
      border-radius: 25px;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;

      span:first-child {
        font-size: 18px;
      }

      span:last-child {
        font-size: 10px;
        margin-top: 2px;
      }
    }
  }
}

.sale-field-box > .title {
  width: 375px;
  height: 50px;
  background: #FA1030;
  display: flex;
  align-items: center;
  justify-content: center;

  .tmall {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 65px;
    height: 30px;

    img {
      width: 40px;
      height: 18px;
    }

    .line {
      display: inline-block;
      width: 26px;
      height: 3px;
      background: #FFFFFF;
      border-radius: 2px;
      margin-top: 6px;
    }
  }

  .jd {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30px;

    img {
      width: 40px;
      height: 18px;
    }

    .line {
      display: inline-block;
      width: 26px;
      height: 3px;
      background: #FFFFFF;
      border-radius: 2px;
      margin-top: 6px;
    }
  }
}

.tmall-bg {
  clear: both;
  width: 375px;
  height: 262px;
  margin: 0;
  padding: 0;
  border: 0;
}

.tmall-box {
  .strategy {
    position: fixed;
    bottom: 140px;
    right: 0;
    width: 76px;
    height: 48px;
    z-index: 3;
  }
}

.jd-box {
  position: relative;
  background: #A41B19;
  padding-bottom: 105px;

  .jd-bg {
    width: 375px;
    height: 309px;
  }

  .get-jd {
    width: 260px;
    height: 50px;
    background: linear-gradient(90deg, #FDD52D 0%, #FFDE80 100%);
    box-shadow: 0px 2px 4px 0px rgba(216, 0, 31, 0.5);
    border-radius: 8px;
    border: 1px solid #FFEE9D;
    font-size: 24px;
    font-weight: 600;
    color: #A41B19;
    line-height: 50px;
    position: absolute;
    top: 274px;
    left: 50%;
    transform: translateX(-50%);

    .jd-arrow-r {
      width: 14px;
      height: 14px;
      margin: 0 0px 2px 6px;

    }
  }

  .today-hot {
    margin-top: 38px;
    text-align: center;

    .title {
      font-size: 22px;
      font-weight: 800;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      .line {
        display: inline-block;
        width: 14px;
        height: 2px;
        background: #FFFFFF;
        border-radius: 1px;
        margin: 0 14px;
      }
    }

    .activity-list {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 360px;
        /*height: 152px;*/
        margin-top: 8px;
      }
    }

    .more {
      width: 140px;
      height: 40px;
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      line-height: 40px;
      text-align: center;
      color: white;
      margin: 20px auto 0 auto;
    }
  }

  .top-list {
    margin-top: 28px;
    text-align: center;

    .title {
      font-size: 22px;
      font-weight: 800;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;

      .line {
        display: inline-block;
        width: 14px;
        height: 2px;
        background: #FFFFFF;
        border-radius: 1px;
        margin: 0 14px;
      }
    }

    .tips {
      height: 21px;
      font-size: 15px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 21px;
      margin-top: 8px;
    }

    .main-box {
      width: 360px;
      /*padding-bottom: 20px;*/
      box-sizing: border-box;
      margin: 0 auto;

      .swiper {
        width: 360px;
        margin: 15px auto 15px auto;
        overflow-y: hidden;
        display: flex;
        justify-content: space-around;
        box-sizing: border-box;

        .swiper-item {
          width: 110px;
          height: 175px;
          background: #FFFFFF;
          border-radius: 8px;
          margin: 9px 0;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;

          .pic {
            width: 110px;
            height: 110px;
            border-radius: 8px;
          }

          .title {
            color: #333333;
            font-size: 12px;
            line-height: 14px;
            overflow: hidden;
            font-weight: normal;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            padding: 0 6px;

          }

          .old-price {
            padding: 0 6px;
            display: flex;
            justify-content: center;
            font-size: 13px;
            font-weight: 400;
            color: #999999;
            line-height: 18px;
            margin-top: 5px;
            text-decoration: line-through;
          }

          .price {
            padding: 0 6px;
            display: flex;
            justify-content: center;
            font-size: 15px;
            font-weight: bold;
            color: #E44A59;
            margin-top: 5px;
          }
        }
      }

      background: #FFEDED;
      box-shadow: 0px 2px 4px 0px rgba(255, 31, 50, 0.6);
      border-radius: 8px;
    }

    .more {
      width: 140px;
      height: 40px;
      border-radius: 8px;
      border: 1px solid #A41B19;
      font-size: 15px;
      color: #A41B19;
      line-height: 40px;
      text-align: center;
      margin: 10px auto 0px auto;
    }
  }

  .activity {
    margin-top: 38px;
    text-align: center;

    .title {
      font-size: 22px;
      font-weight: 800;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      .line {
        display: inline-block;
        width: 14px;
        height: 2px;
        background: #FFFFFF;
        border-radius: 1px;
        margin: 0 14px;
      }
    }

    .activity-list {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 360px;
        /*height: 152px;*/
        margin-top: 8px;
      }
    }
  }

  .recommended {
    margin-top: 28px;
    text-align: center;

    .title {
      font-size: 22px;
      font-weight: 800;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      .line {
        display: inline-block;
        width: 14px;
        height: 2px;
        background: #FFFFFF;
        border-radius: 1px;
        margin: 0 14px;
      }
    }

    .activity-list {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 360px;
        /*height: 152px;*/
        margin-top: 8px;
      }
    }
  }
}

.timeout {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 148px;
  left: 50%;
  transform: translateX(-50%);
}

.timeout > p {
  font-size: 12px;
  color: white;
}

.timeout .timeruning {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 800;
  margin-top: 5px;
  color: white;
}

.timeout .timeruning .i-b {
  width: 24px;
  height: 28px;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 6px;
  color: #DC1E14;
  text-align: center;
  line-height: 28px;
  margin: 0 4px;
}

.step-one {

  .top {
    width: 360px;
    height: 372px;
    background: url("https://cdn.cailu88.com/jingxuanshi/tmall_12_activity.png");
    background-size: cover;
    margin: 0 8px;
    position: relative;

    .button-l {
      width: 176px;
      height: 93px;
      position: absolute;
      bottom: 7px;
      left: 2px;
    }

    .button-r {
      width: 176px;
      height: 93px;
      position: absolute;
      bottom: 7px;
      right: 2px;
    }
  }

  .top2 {
    width: 360px;
    height: 372px;
    background: url("https://cdn.cailu88.com/jingxuanshi/tmall_step2_bg.png");
    background-size: cover;
    margin: 0 8px;
    position: relative;

    .button-l {
      width: 176px;
      height: 93px;
      position: absolute;
      bottom: 7px;
      left: 2px;
    }

    .button-r {
      width: 176px;
      height: 93px;
      position: absolute;
      bottom: 7px;
      right: 2px;
    }
  }

  .every-day-booking {
    width: 375px;
    text-align: center;
    margin-top: 28px;

    .title {
      font-size: 22px;
      font-weight: 800;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;

      .line {
        display: inline-block;
        width: 14px;
        height: 2px;
        background: #FFFFFF;
        border-radius: 1px;
        margin: 0 14px;
      }
    }

    .tips {
      height: 21px;
      font-size: 15px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 21px;
      margin-top: 8px;
    }

    .more {
      width: 140px;
      height: 40px;
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      line-height: 40px;
      text-align: center;
      color: white;
      margin: 20px auto 0 auto;
    }
  }

  .top-list {
    margin-top: 28px;
    text-align: center;

    .title {
      font-size: 22px;
      font-weight: 800;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;

      .line {
        display: inline-block;
        width: 14px;
        height: 2px;
        background: #FFFFFF;
        border-radius: 1px;
        margin: 0 14px;
      }
    }
    .more{
      width: 140px;
      height: 40px;
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      line-height: 40px;
      text-align: center;
      color: white;
      margin: 20px auto 0 auto;
    }

    .tips {
      height: 21px;
      font-size: 15px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 21px;
      margin-top: 8px;
    }

    .swiper {
      width: 360px;
      height: 212px;
      background: #FFEDED;
      box-shadow: 0px 2px 4px 0px rgba(255, 31, 50, 0.6);
      border-radius: 8px;
      margin: 15px auto 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;

      .swiper-box {
        width: 346px;
        margin-top: 9px;
        overflow-y: hidden;
        display: flex;
        justify-content: space-between;
        overflow-x: scroll;

        .top-more {
          width: 60px;
          height: 120px;
        }

        .swiper-item {
          width: 120px;
          height: 194px;
          background: #FFFFFF;
          border-radius: 8px;
          margin-right: 7px;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;

          .pic {
            width: 120px;
            height: 120px;
            border-radius: 8px;
          }

          .title {
            color: #333333;
            font-size: 12px;
            line-height: 14px;
            overflow: hidden;
            font-weight: normal;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            padding: 0 6px;

          }

          .price {
            padding: 0 6px;
            display: flex;
            justify-content: start;
            font-size: 15px;
            font-weight: bold;
            color: #E44A59;
            margin-top: 10px;
          }

          .label {
            margin: 6px 0 0 6px;
            font-size: 10px;
            color: #E44A59;
            display: inline-block;
            width: 100px;
            height: 20px;
            line-height: 20px;
            border-radius: 3px;
            border: 1px solid #E44A59;
          }
        }

      }
    }
  }

  .activity {
    margin-top: 28px;
    text-align: center;

    .title {
      font-size: 22px;
      font-weight: 800;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      .line {
        display: inline-block;
        width: 14px;
        height: 2px;
        background: #FFFFFF;
        border-radius: 1px;
        margin: 0 14px;
      }
    }

    .activity-list {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 360px;
        /*height: 152px;*/
        margin-top: 8px;
      }
    }
  }
}

.step-two {
  position: relative;
  margin-top: 29px;
  margin-bottom: 60px;

  .top {
    width: 360px;
    height: 372px;
    background: url("https://cdn.cailu88.com/jingxuanshi/tmall_step2_bg.png");
    background-size: cover;
    margin: 0 8px;
    position: relative;

    .button-l {
      width: 176px;
      height: 93px;
      position: absolute;
      bottom: 7px;
      left: 2px;
    }

    .button-r {
      width: 176px;
      height: 93px;
      position: absolute;
      bottom: 7px;
      right: 2px;
    }
  }

  .recommended {
    margin-top: 28px;
    text-align: center;

    .title {
      font-size: 22px;
      font-weight: 800;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      .line {
        display: inline-block;
        width: 14px;
        height: 2px;
        background: #FFFFFF;
        border-radius: 1px;
        margin: 0 14px;
      }
    }

    .activity-list {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 360px;
        /*height: 152px;*/
        margin-top: 8px;
      }
    }
  }
}

.goods-list {
  width: 100%;
  padding-top: 8px;

  .goods-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0px 8px 6px 8px;
    padding: 10px;
    max-height: 140px;
    background: #fff;
    border-radius: 8px;
    background: #FFEDED;

    .goods-image {
      & > img {
        width: 120px;
        height: 120px;
        border-radius: 8px;
      }
    }

    .goods-info {
      margin-left: 10px;

      .goods-title {
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        color: #333333;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;

        & > span:first-child {
          font-size: 10px;
          color: #fff;
          margin-right: 7px;
          border-radius: 2px;
        }

        .label-tb {
          font-size: 8px;
          padding: 1px 4px;
          background: #FF8502;
        }

        .label-tm {
          font-size: 8px;
          padding: 1px 4px;
          background: #E44A59;
        }

        .label-jd {
          font-size: 8px;
          padding: 1px 4px;
          background-color: #e44a59;
        }

        .label-pdd {
          font-size: 8px;
          padding: 1px 3px;
          background-color: #ff5d87;
        }

        .label-wph {
          font-size: 8px;
          padding: 1px 3px;
          background-color: #ed008c;
        }

        .label-sn {
          font-size: 8px;
          padding: 1px 3px;
          background-color: #ffaa02;
        }
      }

      .goods-discounts {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 20px;
        font-size: 12px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        /* color: #8b7f08; */
        line-height: 20px;
        margin-top: 3px;

        .goods-coupon,
        .goods-cashback {
          color: #fff;
          background-color: #E44A59;
          border-radius: 2px;
        }

        .goods-cashback {
          padding: 0 4px;
        }

        .goods-coupon {
          padding: 0 8px 0 8px;
          margin-right: 8px;
          position: relative;
          /* overflow: hidden; */
        }

        .goods-coupon:before {
          /* display: inline-block; */
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #fff;
          position: absolute;
          top: 50%;
          left: -2px;
          transform: translate(0, -50%);
        }

        .goods-coupon:after {
          /* display: inline-block; */
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #fff;
          position: absolute;
          top: 50%;
          right: -2px;
          transform: translate(0, -50%);
        }
      }

      .goods-price-volume {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;

        .price {
          color: #999;
          text-decoration: line-through;
        }
      }

      .goods-promotion-volume {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .money-symbol {
          font-size: 13px;
          font-weight: bold;
          color: #e44a59;
          line-height: 18px;
        }

        .goods-current-price {
          height: 30px;
          font-size: 22px;
          font-weight: bold;
          color: #e44a59;
          line-height: 30px;
        }

        .to-details {
          width: 96px;
          height: 28px;
          background: linear-gradient(90deg, #FF004F 0%, #FF5500 100%);
          border-radius: 4px;
          font-size: 14px;
          line-height: 28px;
          text-align: center;
          color: white;

          img {
            width: 6px;
            height: 11px;
            margin-left: 10px;
          }
        }
      }

    }
  }
}
</style>
